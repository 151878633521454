@import "partials/app";


#map {
	position: relative;
	.frameMap {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.countdoun {
	text-align: center;
	margin-bottom: 20px;
	> div {
		display: inline-block;
		@extend .clearfix;
		> div {
			float: left;
			&.devider {
				font-size: 32px;
				font-weight: 800;
				line-height: 1;
				width: 12px;
			}
			span {
				display: block;
				text-align: center;
				width: 48px;
				&:first-child {
					font-size: 38px;
					line-height: 1;
					font-weight: 600;
				}
				&:last-child {
					font-size: 11px;
					line-height: 1;
					padding-top: 3px;
				}
			}
		}
	}
	
}

section{
	h1 {
		@include media-breakpoint-down(sm){
			font-size: 24px;
			margin-bottom: 20px;
		}
	}
	@extend .pt-3, .pb-4;
	&.models {
		i {
			display: block;
			font-size: 36px;
			line-height: 1;
			margin-bottom: .5rem;
		}
		svg.watch {
		    fill: #e6b856;
		    max-height: 36px;
		}
		a.nav-link{
			&:hover{
				svg.watch{
					fill: #d1981f;
				}
			}
			&.active{
				svg.watch{
					fill: #fff;
				}
			}
		}
	}
	&.footer {
		background: #f2f2f2;
		font-size: 12px;
		a {
			color: #333;
		}
		ul.menu {
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}
	&.phone-page {
		.phone-price {
			td, th {
				text-align: center;
				&:first-child {
					text-align: left;
				}
				vertical-align: middle;
			}
		}
	}
	&.main-slider {
		background: url('../img/banner-1-bg.png') center no-repeat;
		background-size: cover;
		color: #333;
		h2 {
			font-size: 44px;
			font-weight: 600;
		}
		.main-img {
			max-width: 700px;
			margin: 0 auto;
		}
		.permisions-list {
			> div {
				padding-left: 20px;
				i {
					position: absolute;
					left: 0;
					top: 3px;
				}
			}
		}
	}
	&.roadmap {
		background: $gray-100;
		.step {
			display: table-row;
			> div {
				display: table-cell;
				vertical-align: middle;
			}
			.num {
				$size: 60px;
				text-align: center;
				border: 1px solid rgba($tree, .4);
				border-radius: 50%;
				line-height: $size;
				font-size: 24px;
				height: $size;
				width: $size;
				margin-right: 20px;
				background: $white;
				box-shadow: 0 0 15px 5px rgba($tree, .4);
				margin: 15px;
			}
			p {
				margin: 0;
			}
			@include media-breakpoint-up(lg){
				display: block;
				> div {
					display: block;
					text-align: center;
				}
				.num{
					$size: 80px;
					line-height: $size;
					font-size: 28px;
					height: $size;
					width: $size;
					margin-right: 20px;
					box-shadow: 0 0 20px 5px rgba($tree, .4);
					margin: 20px auto;
				}
			}
		}
	}
	&.orderform {
		position: relative;
		.container {
			z-index: 1;
			position: relative;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(#000, .5);
			z-index: 0;
		}
		background: url('../img/tile_high_sierra_large_2x.jpg') center no-repeat;
		background-size: cover;
		color: $white;
		text-shadow: 0 1px 1px rgba(#000, 0.30);
		h3 {
			font-size: 28px;
		}
	}
	&.seotext {
		background: $white;
	}
	&.about-top {
		background: #fafafa;
		.title {
			font-size: 48px;
			@include media-breakpoint-down(sm){
				font-size: 28px;
			}
		}
	}
	&.about-content {
		background: #eee;
		// color: #fff;
		.garanty-table {
			@include media-breakpoint-down(sm){
				td{
					&:first-child{
						display: none;
					}
				}
			}
			
		}
	}
	&.remont-banner {
		position: relative;
		overflow: hidden;
		.banner {
			filter: blur(4px);
			position: absolute;
			z-index: 1;
			background-size: cover;
			background-position: center;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			&:before {
				content: '';
				position: absolute;
				background: rgba(#fff, .5);
				top: 0;
				bottom: 0;
				left: -10px;
				right: -10px;
				z-index: 2;
			}
		}
		
		.container {
			position: relative;
			z-index: 3;
			filter: blur(0);
			h1 {
				text-shadow: 0px 0px 5px #fff;
				font-weight: 400;
				font-size: 40px;
				letter-spacing: -2px;
				color: #222;
				b {
					font-size: 60px;
					font-weight: 400;
					color: #000;
				}
				@include media-breakpoint-down(sm){
					font-size: 20px;
					letter-spacing: 0;
					b {
						font-size: 30px;
					}
				}
			}
		}

	}
}

.h-scroll{
	overflow-y: scroll;
	&-300 {
		@extend .h-scroll;
		max-height: 400px;
		@include media-breakpoint-down(sm){
			max-height: initial;
		}
	}
}


.form-sending {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0; top: 0;
		right: 0; bottom: 0;
		background: url('../img/preloader.gif') center rgba(#fff,.5) no-repeat;
		background-size: 30px;
		z-index: 1;
	}
}

.form-sent {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0; top: 0;
		right: 0; bottom: 0;
		background: rgba(#fff,.9);
	}
	.message {
		position: absolute;
		z-index: 2;
		left: 0; right: 0;
		top: 50%;
		text-align: center;
		font-size: 16px;
		transform: translateY(-50%);
		color: #333;
		i {
			display: block;
			margin: 0 auto;
			font-size: 32px;
			&.fa-times {
				color: red;
			}
			&.fa-check {
				color: green;
			}
		}
	}
}

section.orderform {
	.form-sending {
		position: relative;
		> * {
			opacity: 0;
		}
		&:before {
			opacity: 1;
			content: '';
			position: absolute;
			left: 0; top: 0;
			right: 0; bottom: 0;
			background: url('../img/preloader.gif') center rgba(#fff,.5) no-repeat;
			background-size: 30px;
			z-index: 1;
		}
	}
	.form-sent {
		&:before {
			background: rgba(#000,.0);
		}
		> * {
			opacity: 0;
		}
		.message {
			opacity: 1;
			color: #fff;
			i {
				display: block;
				margin: 0 auto;
				font-size: 32px;
				&.fa-times {
					color: red;
				}
				&.fa-check {
					color: green;
				}
			}
		}
	}

}

#formModal2 {
	.form-sent{
		&:before {
			background: rgba(#fff, .9);
			z-index: 1;
		}
	}
}



.matirial-input {
	@extend .rounded-0, .border-top-0, .border-right-0, .border-left-0;
	outline: none;
	background-color: transparent;
	color: $white;
	border-width: 2px;
	@include placeholder {
	    color: rgba($white, .6);
	}
	&:focus{
		box-shadow: none;
		background-color: transparent;
		border-bottom-color: $white;
		color: $white;
		@include placeholder {
		    color: rgba($white, .9);
		}
	}
	
}

.social-links {
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		list-style: none;
		font-size: 32px;
		+ li {
			margin-left: 20px;
		}
	}
}

